<template>
    <div id="send-comment-dialog-component">
        <v-dialog id="send-comment-dialog" v-model="is_send_dialog_open" max-width="800">
            <v-card>
                <v-card-title>{{ $t('parameter_page.send_comment') }}</v-card-title>

                <v-card-text>
                    <v-expand-transition>
                        <div v-if="current_page === 0">
                            <v-btn block class="mb-4" color="secondary" outlined @click="current_page = 1">
                                <v-icon class="pr-2" color="secondary">mdi-message-text-outline</v-icon>
                                <span class="font-weight-bold secondary--text">{{ $t('send_comment_page.give_opinion') }}</span>
                            </v-btn>

                            <v-btn block color="error" @click="current_page = 2">
                                <v-icon class="pr-2" color="white">mdi-message-alert-outline</v-icon>
                                <span class="font-weight-bold white--text">{{ $t('send_comment_page.signal_bug') }}</span>
                            </v-btn>
                        </div>
                    </v-expand-transition>

                    <v-expand-transition>
                        <div v-if="current_page === 1">
                            <p class="font-weight-bold">
                                {{ $t('send_opinion_page.share_your_experience') }}
                            </p>

                            <v-textarea v-model="opinion" :label="$t('send_opinion_page.your_opinion')" filled />

                            <v-btn
                                :disabled="opinion === ''"
                                :loading="sending_comment"
                                block
                                class="btn-secondary-loader"
                                color="primary"
                                large
                                @click="sendOpinion()"
                            >
                                <span :class="`${opinion !== '' ? 'secondary--text' : 'font-color-light'} font-weight-bold `">
                                    {{ $t('send_opinion_page.send_your_opinion') }}
                                </span>
                            </v-btn>
                        </div>
                    </v-expand-transition>

                    <v-expand-transition>
                        <div v-if="current_page === 2">
                            <v-text-field v-model="bug.page_concerned" :label="$t('send_bug_page.page_concerned')" filled />

                            <v-text-field v-model="bug.object" :label="$t('send_bug_page.bug_object')" filled />

                            <v-textarea v-model="bug.details" :label="$t('send_bug_page.bug_details')" filled />

                            <div class="dropzone" @dragenter="dragging = true" @dragleave="dragging = false">
                                <div class="dropzone-info" @drag="onChange">
                                    <div class="dropzone-upload-limit-info pl-3 pt-4 pb-4">
                                        <span :class="dragging ? 'primary--text' : ' font-color-medium'">
                                            {{ $t('send_bug_page.import_image') }}
                                        </span>
                                        <p class="font-color-light font-14 mb-n1 line-height-16">
                                            {{ $t('send_bug_page.drop_or_click_image') }}
                                        </p>
                                        <p class="font-color-light font-10 mb-0">
                                            {{ $t('global.format_pgn_jpeg') }}
                                        </p>
                                        <input multiple type="file" @change="onChange" />
                                    </div>
                                </div>

                                <div v-for="(file, index) in files" :key="index" class="mb-1 ml-2 font-14">
                                    <a :href="urls[index]" target="_blank">{{ file.name }}</a>
                                    <v-icon small @click="files.splice(index, 1)">mdi-close</v-icon>
                                    <!--
                                    <v-row class="ma-0">
                                        <v-col v-for="url in urls" :key="url" >
                                            <v-img :src="url" max-width="200" max-height="200"/>
                                        </v-col>
                                    </v-row>
                                    -->
                                </div>
                            </div>

                            <div class="font-12 mx-1 mt-1 mb-6">
                                <span class="font-color-light">Exemple :</span><br />
                                <span class="font-weight-bold font-color-medium">{{ $t('send_bug_page.behavior_observe') }}</span>
                                <br />
                                <span class="font-color-light font-italic"> {{ $t('send_bug_page.example_bug') }} </span><br />
                                <span class="font-weight-bold font-color-medium">{{ $t('send_bug_page.behavior_achieve') }}</span>
                                <br />
                                <span class="font-color-light font-italic">
                                    {{ $t('send_bug_page.example_solution') }}
                                </span>
                            </div>

                            <v-btn
                                :disabled="!validBug"
                                :loading="sending_comment"
                                block
                                class="btn-secondary-loader"
                                color="primary"
                                large
                                @click="sendBug()"
                            >
                                <span :class="`${validBug !== '' ? 'secondary--text' : 'font-color-light'} font-weight-bold`">
                                    {{ $t('send_bug_page.send_report_bug') }}
                                </span>
                            </v-btn>
                        </div>
                    </v-expand-transition>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import GlobalRepository from '@/repositories/GlobalRepository'

export default {
    name: 'SendCommentDialogComponent',
    data() {
        return {
            is_send_dialog_open: false,
            current_page: 0,
            sending_comment: false,

            opinion: '',

            bug: {
                page_concerned: '',
                object: '',
                details: '',
            },
            files: [],
            dragging: false,
            urls: [],
            valid_format_picture: 'image/png, image/jpeg',
        }
    },
    computed: {
        validBug() {
            return this.bug.page_concerned !== '' && this.bug.object !== '' && this.bug.details !== '' && this.files.length > 0
        },
    },
    methods: {
        openSendCommentDialog() {
            this.bug = {
                page_concerned: '',
                object: '',
                details: '',
            }
            this.opinion = ''
            this.files = []
            this.urls = []
            this.current_page = 0
            this.is_send_dialog_open = true
        },

        sendOpinion() {
            this.sending_comment = true
            GlobalRepository.sendOpinion({ opinion: this.opinion })
                .then(() => {
                    this.showSnackbar('success', this.$t('send_opinion_page.message_send'))
                    this.opinion = ''
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.sending_comment = false
                    this.is_send_dialog_open = false
                })
        },

        sendBug() {
            this.sending_comment = true

            let formData = new FormData()

            this.files.forEach((file) => {
                formData.append('files[]', file)
            })

            formData.append('page_concerned', this.bug.page_concerned)
            formData.append('object', this.bug.object)
            formData.append('details', this.bug.details)

            GlobalRepository.sendBug(formData)
                .then(() => {
                    this.showSnackbar('success', this.$t('send_bug_page.message_send'))
                    this.bug.opinion = ''
                })
                .catch((error) => {
                    this.manageError(error)
                })
                .finally(() => {
                    this.sending_comment = false
                    this.is_send_dialog_open = false
                })
        },

        onChange(e) {
            let files = e.target.files || e.dataTransfer.files
            if (!files.length) {
                this.dragging = false
                return
            }

            if (this.checkFiles(files[0])) {
                this.createFile(files[0])
            }
        },

        checkFiles(new_file) {
            let valid = true

            if (new_file.type === '') {
                this.showSnackbar('error', this.$t('send_bug_page.file_type_not_authorized'))
                valid = false
            }

            if (!this.valid_format_picture.includes(new_file.type)) {
                this.showSnackbar('error', this.$t('send_bug_page.file_type_not_authorized'))
                valid = false
            }

            this.files.forEach((file) => {
                if (file.name === new_file.name) {
                    this.showSnackbar('error', this.$t('send_bug_page.file_already_selected'))
                    valid = false
                }
            })

            return valid
        },

        createFile(file) {
            this.files.push(file)
            this.urls.push(URL.createObjectURL(file))
            this.dragging = false
        },
    },
}
</script>
